{
  "name": "agroit-frontend",
  "version": "0.1.0",
  "author": {
    "name": "Molaris Kft.",
    "email": "hello@molaris.dev"
  },
  "description": "t3ÉTEL - tiszta termesztés termelőtől a fogyasztóig",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:docker": "ng build --configuration=docker",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "prod": "ng serve --configuration=production",
    "dev": "ng serve --configuration=dev",
    "local": "ng serve --configuration=local"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.0",
    "@angular/cdk": "^17.2.0",
    "@angular/common": "^17.2.0",
    "@angular/compiler": "^17.2.0",
    "@angular/core": "^17.2.0",
    "@angular/forms": "^17.2.0",
    "@angular/material": "^17.2.0",
    "@angular/material-moment-adapter": "^17.2.2",
    "@angular/platform-browser": "^17.2.0",
    "@angular/platform-browser-dynamic": "^17.2.0",
    "@angular/router": "^17.2.0",
    "@ng-web-apis/common": "^3.0.6",
    "@ng-web-apis/geolocation": "^3.0.6",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/router": "^6.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angular-feather": "^6.5.1",
    "apexcharts": "3.49.0",
    "bootstrap": "^5.3.2",
    "echarts": "^5.5.0",
    "jwt-decode": "^4.0.0",
    "moment": "^2.30.1",
    "ng-apexcharts": "^1.9.0",
    "ngx-device-detector": "^7.0.0",
    "ngx-mat-multi-sort": "^17.1.0",
    "ngx-scrollbar": "^13.0.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "update": "^0.7.4",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.0",
    "@angular/cli": "^17.2.0",
    "@angular/compiler-cli": "^17.2.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "pnpm": "~8.15.5",
    "typescript": "~5.3.2"
  },
  "overrides": {
    "@angular-material-components/datetime-picker": {
      "@angular/platform-browser": ">=16.0.0",
      "@angular/common": ">=16.0.0",
      "@angular/core": ">=16.0.0",
      "@angular/forms": ">=16.0.0",
      "@angular/material": ">=16.0.0",
      "@angular/cdk": ">=16.0.0"
    },
    "@angular-material-components/moment-adapter": {
      "@angular/common": ">=16.0.0",
      "@angular/core": ">=16.0.0"
    }
  }
}
