import packageInfo from '../../package.json';
import gitInfo from '../../git-version.json';

export const environment = {
  production: false,
  appVersion: packageInfo.version,
  appDescription: packageInfo.description,
  gitDate: gitInfo?.git?.date,
  gitSha1: gitInfo?.git?.sha1.slice(0, 8),
  gitBranch: gitInfo?.git?.branch,
  buildNumber: gitInfo?.buildNumber,
  showVersionInfo: true,
  backendBaseUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/api`,
  api: {
    timeout: 30000,
    proxy: '',
  },
};
