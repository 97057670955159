import { environment as env } from '../../../environments/environment';

/**
 * Az API-t megvalósító osztályok ős osztálya
 */
export class BaseApi {
  constructor() {}

  /**
   * Visszaadja az alap url-t
   * @returns {string} Alap URL
   *
   */
  protected getBaseUrl(): string {
    const proxy = env.api.proxy;

    if (proxy && proxy.length > 0) {
      return  `${env.backendBaseUrl}/${proxy}`;
    }

    return env.backendBaseUrl;

    
    // if (proxy && proxy.length > 0) {
    //   return `/${proxy}`;
    // }

    // return '';
  }
}
